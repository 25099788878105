export default [
  {
    id: 1,
    text: "Terms of use",
    url: "/terms-of-use/",
  },
  {
    id: 2,
    text: "Privacy policy",
    url: "/privacy-policy/",
  },
  {
    id: 3,
    text: "Disclaimer",
    url: "/disclaimer/",
  },
]
