import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Button = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 1.5rem;
  border-radius: ${props => props.theme.radius};
  background-color: ${props => props.theme.colors.primary} /*fallback*/
  background: ${props => props.theme.colors.buttonGradient.gradient};
    background: ${props => props.theme.colors.buttonGradient.gradientWebkit};
    background: ${props => props.theme.colors.buttonGradient.gradientO};  
  /* cuz i had 2 lateral lines , i did this */
  background-position-x: 10%;;
  background-size: 150%;
  box-shadow: ${props => props.theme.elevations.elevation3};
  -webkit-box-shadow: ${props => props.theme.elevations.elevation3};
  /* rules for the element that contains it */
  display: ${props => (props.isOpen ? "block" : "none")} ;
    padding: 0.2rem 0.4rem;
    margin: 0.5rem auto 0 auto;
  }
  @media screen and (min-height: 300px) {
    height: 2rem;
  }
  @media screen and (min-height: 400px) {
    margin: 1rem auto 0 auto;
  }
  @media screen and (min-height: 450px) {
    margin: 2rem auto 0 auto;
  }
  @media screen and (min-height: 500px) {
    margin: 2rem auto 0 auto;
  }
  @media screen and (min-height: 600px) {
    margin: 3rem auto 0 auto;
  }
  @media screen and (min-height: 750px) {
    margin: 4rem auto 0 auto;
  }
  ${
    "" /* @media screen and (min-height: 800px) {
    margin: 5rem auto 0 auto;
  } */
  }
  
  @media screen and (min-width: 900px) {
    grid-column: 4 / 5;
    margin-top: auto;
    margin-bottom:auto; 
    justify-self: right;
    margin-left: 2.5rem;
    display: block;
    ${"" /* place-items: center; */}
  }
  
  /* rules for the elements inside it */
  transition: ${props => props.theme.transition};
  :hover {
      transform: scale(1.1);
  }
`

const ButtonLink = styled(Link)`
  color: ${props => props.theme.colors.buttonText};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: ${props => props.theme.spacing};
  ${Button}:hover & {
  }
`

const CommunityButton = ({ buttonText, link, isOpen }) => {
  return (
    <Button isOpen={isOpen} onClick={e => e.preventDefault}>
      <ButtonLink to={link ? link : "#"}>{buttonText} </ButtonLink>
    </Button>
  )
}

export default CommunityButton
