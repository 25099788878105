import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { ThemeContext } from "./Provider"
import logo from "../assets/logo.svg"
import SideMenuLinks from "./SideMenuLinks"
import CommunityButton from "./CommunityButton"
import SocialLinks from "./SideMenuSocialLinks"
import { FaTimes } from "react-icons/fa"

const AsideNavbar = styled.aside`
  background: ${props => props.theme.colors.surface1};
  position: fixed;
  top: 0;
  left: 0;
  width: 30%;
  min-width: 150px;
  height: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
  opacity: 1;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: 0.5s;
  box-shadow: ${props => props.theme.elevations.elevation3};
  @media screen and (min-width: 900px) {
    transform: translateX(-100%);
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: ${props => props.theme.colors.H1H2};
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    color: ${props => props.theme.colors.primary};
    transform: scale(1.1);
  }
`

const Logo = styled.img`
  height: 100%;
  width: 100%;
`

const LogoLink = styled(Link)`
  position: absolute;
  top: 5%;
  height: 30%;
  width: 45%;
  display: grid;
  place-items: center;
`

const Div = styled.div`
  position: absolute;
  top: 30%;
  margin-top: 5%;
`

const SideMenu = props => {
  const { isOpen, toggleSideMenu } = useContext(ThemeContext)
  return (
    <AsideNavbar isOpen={isOpen}>
      <CloseButton onClick={toggleSideMenu}>
        <FaTimes />
      </CloseButton>
      <LogoLink to="/">
        <Logo src={logo} alt="logo" />
      </LogoLink>
      <Div>
        <SideMenuLinks />
        <CommunityButton
          isOpen={isOpen}
          buttonText="Community"
          link="/community"
        />
        <SocialLinks styleClass={`${isOpen ? "sidemenu-icons" : ""}`} />
      </Div>
    </AsideNavbar>
  )
}

export default SideMenu
