import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import legalLinks from "../constants/legalLinks"

// .legal-links
const LegalList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  @media screen and (min-width: 841px) {
    margin-right: 0.2rem;
  }
`

// .legal-link
const LegalLink = styled(Link)`
  display:block;
  font-size: 1rem;
  text-align: center;
  color: ${props => props.theme.colors.navLinkText};
  border-radius: ${props => props.theme.radius};
  transition: ${props => props.theme.transition};
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
  :hover {
    ${"" /* color: ${props => props.theme.colors.H1H2}; */}
    background-color: ${props => props.theme.colors.surface2};
    box-shadow: ${props => props.theme.elevations.elevation3};
  }
`

const LegalLinks = () => {
  return (
    <LegalList>
      {legalLinks.map(legalLink => {
        const { id, url, text } = legalLink
        return (
          <li key={id}>
            <LegalLink to={url} className="legal-link">
              {text}
            </LegalLink>
          </li>
        )
      })}
    </LegalList>
  )
}

export default LegalLinks
