import React from "react"
import styled from "styled-components"
import socialLinks from "../constants/socialLinks"

const SocialList = styled.ul`
  margin: 0 auto 1rem auto;
  width: 7rem;
  display: flex;
  justify-content: space-between;
`

const SocialLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.H3toH6};
  transition: ${props => props.theme.transition};
  border-radius: ${props => props.theme.radius};
  display: flex;
  padding: 3px;
  :hover {
    color: ${props => props.theme.colors.bg};
    background-color: ${props => props.theme.colors.H3toH6};
  }
`

const FooterSocialLinks = () => {
  return (
    <SocialList>
      {socialLinks.map(socialLink => {
        const { id, url, icon } = socialLink
        return (
          <li key={id}>
            <SocialLink href={url}>{icon}</SocialLink>
          </li>
        )
      })}
    </SocialList>
  )
}

export default FooterSocialLinks
