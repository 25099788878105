import React from "react"
import styled from "styled-components"
import LegalLinks from "./LegalLinks"
import SocialLinks from "./FooterSocialLinks"

const StyledFooter = styled.footer`
  height: 10rem;
  background: ${props => props.theme.colors.surface1};
  box-shadow: ${props => props.theme.elevations.elevation3};
  text-align: center;
  display: grid;
  place-items: center;
`

const StyledH4 = styled.h4`
  margin: 0.5rem 0;
  color: ${props => props.theme.colors.H3toH6};
  font-weight: normal;
  text-transform: uppercase;
`
const Span = styled.span`
  font-weight: bold;
`

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <SocialLinks styleClass="footer-links-s" />
        <StyledH4>
          &copy; {new Date().getFullYear()}
          <Span> Young Neuros</Span> Made by Catalina
        </StyledH4>
        <LegalLinks />
      </div>
    </StyledFooter>
  )
}

export default Footer
