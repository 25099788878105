import React, { useContext } from "react"
import styled from "styled-components"
import Navbar from "./Navbar"
import SideMenu from "./SideMenu"
import Footer from "./Footer"
import { ThemeContext } from "./Provider"

const LayoutDiv = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: ${props => props.theme.fonts.secondary};
  background-color: ${props => props.theme.colors.bg};
`

const Main = styled.main`
  margin-top: 3rem;
  @media screen and (min-width: 600px) {
    margin-top: 5rem;
  }
  margin-left: 0;
  @media screen and (min-width: 300px) {
    margin-left: ${props => (props.isOpen ? "150px" : "0")};
  }
  @media screen and (min-width: 500px) {
    margin-left: ${props => (props.isOpen ? "30%" : "0")};
  }
  @media screen and (min-width: 900px) {
    margin-left: 0;
  }
  transition: 0.5s;
`

const Layout = ({ children }) => {
  const { isOpen } = useContext(ThemeContext)
  return (
    <LayoutDiv>
      {/* somehow anyway my grid doesn't work if i don't put the navbar in a div, but it is better for both menus anyway */}
      <div>
        <Navbar />
        <SideMenu />
      </div>
      <Main isOpen={isOpen}>{children}</Main>
      <Footer />
    </LayoutDiv>
  )
}

export default Layout
