import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../assets/logo.svg"
import NavLinks from "./NavMenuLinks"
import SideMenuButton from "./SideMenuButton"
import ToggleThemeButton from "./ToggleThemeButton"
import CommunityButton from "./CommunityButton"

const NavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 3rem;
  z-index: 200;
  background: ${props => props.theme.colors.surface1};
  box-shadow: ${props => props.theme.elevations.elevation3};
  display: grid;
  grid-template-columns: 8% 1fr 1fr minmax(min-content, min-content) 8%;
  grid-template-columns:
    8% 1fr 1fr minmax(-webkit-min-content, -webkit-min-content)
    8%;
  -ms-grid-columns: 8% 1fr 1fr minmax(-webkit-min-content, -webkit-min-content)
    8%;
  -ms-grid-columns: 8% 1fr 1fr minmax(min-content, min-content) 8%;
  grid-template-rows: 80%;
  align-content: center;
  @media screen and (min-width: 600px) {
    height: 5rem;
  }
`
const Logo = styled.img`
  align-self: center;
  justify-self: left;
  height: 100%;
`
const LogoLink = styled(Link)`
  grid-column: 2 / 3;
  display: flex;
  align-content: center;
  justify-content: left;
`

const Navbar = props => {
  return (
    <NavBar>
      <LogoLink to="/">
        <Logo src={logo} alt="logo" />
      </LogoLink>
      <SideMenuButton />
      <NavLinks />
      <CommunityButton buttonText="Community" link="/community" />
      <ToggleThemeButton />
    </NavBar>
  )
}

export default Navbar
