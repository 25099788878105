import React, { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "./Provider"
import { AiOutlineMenu } from "react-icons/ai"

const SideButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2rem;
  width: 3rem;
  height: 80%;
  align-self: center;
  justify-self: right;
  background: transparent;
  color: ${props => props.theme.colors.H1H2};
  transition: ${props => props.theme.transition};
  border-radius: ${props => props.theme.radius};
  display: grid;
  place-items: center;
  :hover {
    color: ${props => props.theme.colors.primary};
    transform: scale(1.1);
  }
  margin-right: 0.5rem;
  @media screen and (min-width: 500px) {
    margin-right: 0;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
`
const SideMenuButton = () => {
  const { toggleSideMenu } = useContext(ThemeContext)
  return (
    <SideButton type="button" onClick={toggleSideMenu}>
      <AiOutlineMenu />
    </SideButton>
  )
}

export default SideMenuButton
