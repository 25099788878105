import React from "react"
import {
  TiSocialFacebook,
  TiSocialLinkedin,
  TiSocialTwitter,
} from "react-icons/ti"

export default [
  {
    id: 1,
    icon: <TiSocialFacebook />,
    url: "https://www.twitter.com",
  },
  {
    id: 2,
    icon: <TiSocialLinkedin />,
    url: "https://www.twitter.com",
  },
  {
    id: 3,
    icon: <TiSocialTwitter />,
    url: "https://www.twitter.com",
  },
]
