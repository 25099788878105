export default [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/about/",
  },
  {
    id: 3,
    text: "pathologies",
    url: "/pathologies/",
  },
  {
    id: 4,
    text: "neuroskills",
    url: "/neuro-skills/",
  },
  {
    id: 5,
    text: "blog",
    url: "/blog/",
  },
  // {
  //   id: 6,
  //   text: "contact",
  //   url: "/contact/",
  // },
]
