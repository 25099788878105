import React, { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "./Provider"
import { RiMoonFill, RiSunFill } from "react-icons/ri"

const ToggleButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 16px;
  height: 32px;
  border-radius: 50px;
  padding: 0.2rem;
  margin-right: 2rem;
  position: relative;
  background: ${props => props.theme.colors.surface6};
  box-shadow: inset 0px 0 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0px 0 4px rgba(0, 0, 0, 0.25);
  grid-column: 5 / 6;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 500px) {
    margin: 0 auto;
  }
  ${"" /* @media screen and (min-width: 350px) {
    margin: 0 2rem;
  } */}

  ${"" /* @media screen and (min-width: 500px) and (max-width: 768px) {
    margin: 0 2rem;
  } */}
  :focus {
    outline: none;
    appearance: none;
    border: none;
  }
`
const SunIcon = styled(RiSunFill)`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  color: ${props => props.theme.colors.primary};
  opacity: ${props => (props.isDark ? "0" : "1")};
`

const MoonIcon = styled(RiMoonFill)`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  color: ${props => props.theme.colors.primaryLight};
  opacity: ${props => (props.isDark ? "1" : "0")};
`

const ToggleThemeButton = props => {
  const { handleClick, darkMode } = useContext(ThemeContext)
  return (
    <ToggleButton onClick={handleClick}>
      <SunIcon isDark={darkMode} />
      <MoonIcon isDark={darkMode} />
    </ToggleButton>
  )
}

export default ToggleThemeButton
