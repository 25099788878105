import React from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import menuLinks from "../constants/menuLinks"

const slideRight = keyframes`
0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

// sidemenu-links - actually not existing in the other version
const MenuList = styled.ul``

// .sidemenu-links li
const SideMenuLi = styled.li`
  ${"" /* opacity: 1; */}
  ${"" /* animation: ${slideRight} 0.5s ease-in-out 0.3s forwards;
  :nth-of-type(1) {
    animation-delay: 0.1s;
  }
  :nth-of-type(2) {
    animation-delay: 0.2s;
  }
  :nth-of-type(3) {
    animation-delay: 0.3s;
  }
  :nth-of-type(4) {
    animation-delay: 0.4s;
  }
  :nth-of-type(5) {
    animation-delay: 0.5s;
  }
  :nth-of-type(6) {
    animation-delay: 0.6s;
  }
  :nth-of-type(7) {
    animation-delay: 0.7s;
  }  */}
`

// .sidemenu-links li a
const SideMenuLink = styled(Link)`
  display: block;
  text-align: center;
  font-family: ${props => props.theme.fonts.secondary};
  text-transform: uppercase;
  color: ${props => props.theme.colors.navLinkText};
  letter-spacing: ${props => props.theme.spacing};
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  margin-bottom: 0.2rem;
  transition: ${props => props.theme.transition};
  @media screen and (min-height: 500px) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }

  :hover {
    border-radius: ${props => props.theme.radius};
    box-shadow: ${props => props.theme.elevations.elevation3};
    background-color: ${props => props.theme.colors.surface2};
  }
  &.active {
    border-radius: ${props => props.theme.radius};
    box-shadow: ${props => props.theme.elevations.elevation3};
    background-color: ${props => props.theme.colors.surface2};
  }
`

const SideMenuLinks = () => {
  return (
    <MenuList>
      {menuLinks.map(menuLink => {
        const { id, url, text } = menuLink
        return (
          <SideMenuLi key={id}>
            <SideMenuLink to={url} activeClassName="active">
              {text}
            </SideMenuLink>
          </SideMenuLi>
        )
      })}
    </MenuList>
  )
}

export default SideMenuLinks
